import * as React from "react"
import gatsbyConfig from "../../gatsby-config"
import { Helmet } from "react-helmet"
import OgImg from 'images/image--top1.webp'

import { WAVY_LINE_COLOR } from 'consts'
import SectionHeader from 'components/molecules/section-header'
import { BackButton } from 'components/atoms/back-button'

// sections
import { PcHeader, SpHeader } from 'components/organisms/header'
import Footer from 'components/organisms/footer'

import 'styles/index.scss'

const Thanks = () => {

  const section = 'thanks'
  const title = 'WaaF | お問い合わせ完了'
  
  setTimeout (() => {
    window.location.href = '/'
  }, 5000)

  return (
    <main className="theme">

      <Helmet>
        <html lang={ gatsbyConfig.siteMetadata.lang } />
        <title>{ title }</title>
        <meta name="description" content={ gatsbyConfig.siteMetadata.description } />
        <meta name="keywords" content={ gatsbyConfig.siteMetadata.keywords } />
        <meta name="image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:title" content={ title } />
        <meta property="og:site_name" content={ title } />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ gatsbyConfig.siteMetadata.siteUrl } />
        <meta property="og:image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:description" content={ gatsbyConfig.siteMetadata.description } />
        <meta property="og:locale" content="ja_JP" />
        <link rel="shortcut icon" href="../images/icon.png" type="image/x-icon" />
        <link rel="apple-touch-icon-precomposed" href="../images/icon.png" />
      </Helmet>

      <PcHeader />
      <SpHeader />

      <section className={section}>
        <SectionHeader
          text='お問い合わせ完了'
          lineColor={ WAVY_LINE_COLOR.NAVY }
        />
        <p>
          ありがとうございます。<br />
          お問い合わせが完了しました。
        </p>
        <div className={`${section}__note`}>
          5秒後に自動的にトップページに戻ります。
        </div>
        <div className={`${section}__button-box`}>
          <BackButton />
        </div>
      </section>

      <Footer />
    </main>
  )
}

export default Thanks
